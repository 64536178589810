const DHeadMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'audit.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-dashboard-line',
    active_icon: 'ri-dashboard-fill'
  },
  {
    title: 'Disbursements',
    is_heading: true,
    is_active: false,
    link: {
      name: 'audit.disbursements'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Payment Vouchers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'audit.payment-vouchers'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-profile-line',
    active_icon: 'ri-profile-fill'
  },
  {
    title: 'Budgets',
    is_heading: true,
    is_active: false,
    link: {
      name: 'audit.budgets'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Item Centers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'audit.item-centers'
    },
    is_icon_class: true,
    icon: 'ri-briefcase-5-line',
    active_icon: 'ri-briefcase-5-fill'
  }
]

export default DHeadMenu
